<template>
  <div
    style="justify-content: space-between !important"
    class="
      min-h-screen
      flex flex-col
      items-center
      space-y-5
      sm:space-y-16
      bg-gray-50
      py-12
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div class="max-w-2xl w-full space-y-8">
      <div>
        <img class="mx-auto h-16 w-auto" src="/img/logo.svg" alt="Workflow" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {{ $t("Register a new account") }}
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{ $t("Or") }}
          {{ " " }}
          <router-link
            to="/login"
            class="font-medium text-fuchsia-600 hover:text-fuchsia-500"
          >
            {{ $t("Sign in here") }}
          </router-link>
        </p>
      </div>
      <form class="grid grid-cols-12 gap-6" @submit.prevent="register">
        <div class="col-span-12 sm:col-span-6">
          <label for="username" class="block text-sm font-medium text-gray-700">
            {{ $t("Username") }} *
          </label>
          <div class="mt-1">
            <input
              id="username"
              name="username"
              type="text"
              autocomplete="username"
              required
              v-model="user.username"
              class="
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none
                focus:ring-fuchsia-500
                focus:border-fuchsia-500
                sm:text-sm
              "
            />
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label for="email" class="block text-sm font-medium text-gray-700">
            {{ $t("Email address") }}
          </label>
          <div class="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              v-model="user.email"
              class="
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none
                focus:ring-fuchsia-500
                focus:border-fuchsia-500
                sm:text-sm
              "
            />
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label for="password" class="block text-sm font-medium text-gray-700">
            {{ $t("Password") }} *
          </label>
          <div class="mt-1">
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              v-model="user.password"
              class="
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none
                focus:ring-fuchsia-500
                focus:border-fuchsia-500
                sm:text-sm
              "
            />
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label
            for="password_confirmation"
            class="block text-sm font-medium text-gray-700"
          >
            {{ $t("Confirm Password") }} *
          </label>
          <div class="mt-1">
            <input
              id="password_confirmation"
              name="password_confirmation"
              type="password"
              required
              v-model="user.password_confirmation"
              class="
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none
                focus:ring-fuchsia-500
                focus:border-fuchsia-500
                sm:text-sm
              "
            />
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label for="land" class="block text-sm font-medium text-gray-700">{{
            $t("Contry")
          }}</label>
          <select
            id="land"
            name="land"
            v-model="user.land"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option v-for="(land, i) in lands" :key="i" :value="land">
              {{ $t(land) }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6" v-if="user.land == 'Italien'">
          <label
            for="province"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("Province") }}</label
          >
          <select
            id="province"
            name="province"
            v-model="user.province"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option
              v-for="(province, i) in provinces"
              :key="i"
              :value="province.sigla"
            >
              {{ province.name }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label for="alter" class="block text-sm font-medium text-gray-700">{{
            $t("age")
          }}</label>
          <select
            id="alter"
            name="alter"
            v-model="user.alter"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option v-for="(alter, i) in alterList" :key="i" :value="alter.id">
              {{ $t(alter.name) }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label for="person" class="block text-sm font-medium text-gray-700">{{
            $t("People in your household (including yourself)")
          }}</label>
          <select
            id="person"
            name="person"
            v-model="user.person"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option
              v-for="(person, i) in personList"
              :key="i"
              :value="person.id"
            >
              {{ $t(person.name) }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label for="person_a" class="block text-sm font-medium text-gray-700"
            >{{ $t("Individuals with their own income") }}
          </label>
          <select
            id="person_a"
            name="person_a"
            v-model="user.person_a"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option
              v-for="(person, i) in personList"
              :key="i"
              :value="person.id"
            >
              {{ $t(person.name) }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label
            for="children"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("children") }}</label
          >
          <select
            id="children"
            name="children"
            v-model="user.children"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option
              v-for="(child, i) in childrenList"
              :key="i"
              :value="child.id"
            >
              {{ $t(child.name) }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label for="job" class="block text-sm font-medium text-gray-700">{{
            $t("Occupation of main earner (s)")
          }}</label>
          <select
            v-model="user.job"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option v-for="(job, i) in OccupationList" :key="i" :value="job.id">
              {{ $t(job.name) }}
            </option>
          </select>
        </div>
        <div class="col-span-12">
          <button
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-fuchsia-600
              hover:bg-fuchsia-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-fuchsia-500
            "
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                class="h-5 w-5 text-fuchsia-500 group-hover:text-fuchsia-400"
                aria-hidden="true"
              />
            </span>
            {{ $t("Sign up") }}
          </button>
        </div>
      </form>
    </div>
    <Footer class="col-span-full" />
  </div>
</template>

<script>
const lands = ["Italien", "Österreich", "Deutschland", "Schweiz", "Anderes"];
const alterList = [
  { name: "bis 18 Jahre", id: 1 },
  { name: "19 - 35 Jahre", id: 2 },
  { name: "36 - 65 Jahre", id: 3 },
  { name: "über 65 Jahre", id: 4 },
];
const personList = [
  { name: "1", id: 1 },
  { name: "2", id: 2 },
  { name: "3", id: 3 },
  { name: "4", id: 4 },
  { name: "5 and more", id: 5 },
];
const childrenList = [
  { name: "0", id: 0 },
  { name: "1", id: 1 },
  { name: "2", id: 2 },
  { name: "3 and more", id: 3 },
];
const OccupationList = [
  { name: "Arbeiter/Arbeiterin", id: "ARB" },
  { name: "Angestellte/r", id: "ANG" },
  { name: "Führungskraft", id: "FUH" },
  { name: "Selbstständige/r", id: "SEL" },
  { name: "Unternehmer/in", id: "UNT" },
  { name: "Freiberufler/in", id: "FRE" },
  { name: "Rentner/in", id: "REN" },
  { name: "Anderer", id: "AND" },
];
import { LockClosedIcon } from "@heroicons/vue/solid";
import Footer from "../components/footer.vue";
export default {
  components: {
    LockClosedIcon,
    Footer,
  },
  data() {
    return {
      user: {
        language: localStorage.getItem("haushaltsLang"),
      },
      lands,
      alterList,
      personList,
      childrenList,
      OccupationList,
    };
  },
  computed: {
    provinces() {
      return this.$store.state.User.provinces.data;
    },
  },
  methods: {
    register() {
      this.$store.dispatch("User/register", this.user).then((res) => {
        if (res.type == "error") {
          Object.keys(res.errors).map((i) => {
            this.$emit("showNotif", {
              type: res.type,
              msg: this.$t(res.errors[i][0]),
              showNotif: true,
            });
          });
        }
        if (res.type == "success") {
          this.$emit("close");
          this.$router.push("/dashboard");
          this.$emit("showNotif", {
            type: res.type,
            msg: res.msg,
            showNotif: true,
          });
        }
      });
    },
    GetProvinces() {
      this.$store.dispatch("User/GetProvinces");
    },
  },
  mounted() {
    this.GetProvinces();
  },
};
</script>
